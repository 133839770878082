import React from 'react';
import Layout from '../components/layout/Layout';
import { SearchMetric } from '../components/SearchMetric';

export default () => (
    <Layout>
        <section id="metric-search" className="pt-5 md:pt-5 mb-64">
            <div className="container h-full mx-auto px-8">
                <div className="pb-10 mt-5 ml-4">
                    <div className="font-semibold text-2xl">
                        Explore our financial metrics
                    </div>
                    <div className="text-base text-gray-500 font-medium">
                        Enrich your spreadsheets with over 10 million data points
                    </div>
                </div>
                <SearchMetric />
            </div>
        </section>
    </Layout >
);
